<div class="row" style="cursor: pointer;">
  <div class="col-12">
    <app-year-over-year [customerKey]="customerKey" [productLine]="productLine" [allBrandsOnly]="true" (click)="onClick()"></app-year-over-year>
  </div>
  <!-- 
  <div class="chart col-12 col-sm-12 col-md-6 pt-4">
    <canvas baseChart
      [data]="pieChartData1"
      [labels]="pieChartLabels"
      [chartType]="pieChartType"
      [options]="pieChartOptions"
      [colors]="pieChartColors"
      [legend]="pieChartLegend">
    </canvas>
  </div>
  <div class="chart col-12 col-sm-12 col-md-6 pt-4">
    <canvas baseChart
      [data]="pieChartData2"
      [labels]="pieChartLabels"
      [chartType]="pieChartType"
      [options]="pieChartOptions"
      [colors]="pieChartColors"
      [legend]="pieChartLegend">
    </canvas>
  </div>
  <div class="chart col-12 col-sm-12 col-md-6 pt-4">
    <canvas baseChart
      [data]="pieChartData3"
      [labels]="pieChartLabels"
      [chartType]="pieChartType"
      [options]="pieChartOptions"
      [colors]="pieChartColors"
      [legend]="pieChartLegend">
    </canvas>
  </div> -->
</div>