import { Injectable } from '@angular/core';
import { Adapter } from './adapter';

export class MonthYearProductLineSales {
  constructor(
    public productLine: string,
    public year: number,
    public month: number,
    public quantity: number,
    public samples: number,
    public reOrders: number
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export class MonthYearProductLineSalesAdapter implements Adapter<MonthYearProductLineSales> {

  adapt(item: any): MonthYearProductLineSales {
    return new MonthYearProductLineSales(
      item.productLine,
      item.year,
      item.month,
      item.quantity,
      item.samples,
      item.reOrders
    );
  }
}
