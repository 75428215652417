
<div class="spinner-container">
   
  <div style="display: block" *ngIf="isLoaded">
    <!-- <div class="chart-title-container">
      <h3 class="chart-title">{{title}}</h3>
    </div> -->
    
    <div class="container">
      <div class="row">
        <div class="col-sm-9">
          <div class="card">
            <div class="card-body">
                <div style="font-weight: bold; color: #767676"><span>{{title}}</span></div>
                <div style="color: #767676">{{chartTitle}} Month-on-Month</div>
                <div class="years">
                  <ul>
                    <li *ngFor="let year of yearsList; let i = index" (click)=filter(i);>
                      <div class="year-item" [ngStyle]="{'background-color':chartColors2[i].backgroundColor}"><div [ngStyle]="{'color':lightOrDark(chartColors2[i].backgroundColor)}">{{year}}</div></div>
                    </li>
                  </ul>
                </div>
                <canvas #canvas id="canvas" baseChart height="235"
                [data]="chartData"
                [labels]="chartData.labels"
                [colors]="chartColors"
                [options]="chartOptions"
                [chartType]="chartType"
                [plugins]="labelChartPlugins">
              </canvas>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card">
            <div class="card-body">
              <app-total-data-chart
                [rawData]="totalData"
                [chartColors]="chartColors2"
                [title]="title">
              </app-total-data-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template>
      <div *ngIf="isLoaded">
          <div class="title">No Orders </div>
      </div>
  </ng-template>
</div>
