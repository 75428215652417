import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.css']
})
export class BadgesComponent implements OnInit {
  @Input() customerKey: string;
  @Input() statusColors:any;
  public badges: any;
  public badges_active: any;
  public active_year: number;
  public badges_pending: any;
  public pending_year: number;
  public year: number;
  public isLoaded = false;
  public incentiveMode = "bar"

  public yearCurrent: any = new Date(Date.now()).getFullYear();
  public yearNext: any = this.yearCurrent + 1;

  @Output() updateHeight = new EventEmitter<string>();

  constructor(private dashboardService: DashboardService) { }

  ngOnInit() {
    const apiPath = 'sales/premierstatus2/';
    let diamondcount = 0;

    this.dashboardService.getApiData(this.customerKey, apiPath).subscribe((response) => {
      this.badges = response;

      //this.badges = new Array(response[0]);
      //this.badges.find(x => x.imageUrl = '../../../assets/images/Silver.png');
      
      this.badges_pending = this.badges.filter(row => row.type === 'Pending');

      this.badges_active = this.badges.filter(row => row.type === 'Assigned');
      


      if (this.badges_active.length > 0) {
        this.active_year = this.badges_active[0].premierYear;
      }

      if (this.badges_pending.length > 0) {
        this.pending_year = this.badges_pending[0].premierYear;
      }
      this.isLoaded = true;
      this.updateHeight.emit("UpdateHeight");
    });
  }

  lightOrDark(color): string {
    // Variables for red, green, blue values
    let r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

      r = color[1];
      g = color[2];
      b = color[3];
    }
    else {

      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace(
        color.length < 5 && /./g, '$&$&'));

      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp < 155.5) {

      return '#FFFFFF';
    }
    else {

      return '#000000';
    }
  }

}
