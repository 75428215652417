<div id="replacements" class="col-md-12">
    <app-spinner [visible]="!isLoaded"></app-spinner>
    <div id="filter" (click)="closeClick()"></div>
    <div id="confirmation">
        <div class="productInfoPicture">
            <div class="imageContainer"><img class="confirmationImage" [src]="confirmationImageUrl"></div>
        </div>
        <div class="confirmationText">
            <div class="alertTextContainer">
                    <div class="cancel" (click)="closeClick()">
                            &nbsp; X
                        </div>
                <div class="center" [innerHTML]="alertText"></div>
                <div class="options" *ngIf="!isConfirmed">
                    <div *ngIf="confirmationColors">
                        <label>Color: </label>
                        <select id="colors" (change)="onChange($event)" (click)="onChange($event)">
                            <option disabled selected value="notSelected"> -- select a color -- </option>
                            <option *ngFor="let color of confirmationColors" value={{color}}>
                                {{color}}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="confirmationSizes">
                        <label>Size: </label>
                        <select id="sizes" (change)="onChange($event)" (click)="onChange($event)">
                            <option disabled selected value="notSelected"> -- select a size -- </option>
                            <option *ngFor="let sizes of confirmationSizes" value={{sizes}}>
                                {{sizes}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="confInfoContainer">
                <div class="confInfo">
                    <b>{{confirmationName}}</b>
                </div>
                <div class="confStatus">Expires: {{confirmationExpirationDate}}</div>
                <div class="confOrder" (click)="placeOrder()" *ngIf="!isConfirmed">Confirm Order</div>
            </div>
        </div>
    </div>
    <h4 class="title">MY GOWNS ELIGIBLE FOR SAMPLE REPLACEMENT</h4>
    <div *ngIf="records?.productLines.length > 0 && isLoaded;else no_samples">
        <div class="replacements">
            <div class="styles">
                <div *ngFor="let line of records.productLines"
                    [ngClass]="{'nav-highlight': style === line.name, 'nav-no-highlight': style != line.name }"
                    (click)="switchStyle(line.name)">{{line.name}}</div>
            </div>
            <div class="productsContainer">
                <div class="products" *ngFor="let product of records.productLines[getStyleNumber(style)].products">
                    <div class="productInfoPicture">
                        <div class="imageContainer"><img [src]="product.imageUrl"></div>
                        <div class="infoContainer">
                            <div class="info">
                                <b>{{product.name}}</b>
                            </div>
                            <div class="order"
                                (click)="onClick(product.name, product.sku, product.expirationDate, product.imageUrl, product.colors, product.sizes)">
                                Order Now</div>
                            <div class="status">Expires: {{product.expirationDate}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="incentive rewardPolicy">
            <p>Sample Replacement criteria</p>
            <p class="fineText">10 re-orders must be placed within 12 months of original sample invoice date. 
                Retailers have 60 days to order the 50% discounted sample replacement or the offer expires.</p>
          </div>
    </div>
    <ng-template #no_samples>
        <div *ngIf="isLoaded">
            <h5 class="noreplacements">No gowns currently eligible for sample replacement</h5>
            <div class="incentive rewardPolicy">
                <h5>Sample Replacement criteria</h5>
                <p class="fineText">10 re-orders must be placed within 12 months of original sample invoice date. 
                    Retailers have 60 days to order the 50% discounted sample replacement or the offer expires.</p>
              </div>
        </div>
        
    </ng-template>
</div>