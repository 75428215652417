import { Component, OnInit, Input, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { ChartType, ChartData, ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { YearOverYearService } from '../../../services/year-over-year.service';

@Component({
  selector: 'app-total-data-chart',
  templateUrl: './total-data-chart.component.html',
  styleUrls: ['./total-data-chart.component.css']
})
export class TotalChartDataComponent implements OnInit, OnChanges {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  
  @Input() rawData: ChartData<'bar'>;
  @Input() title: string;
  @Input() chartColors: Array<any>;

  public labelChartPlugins = [DataLabelsPlugin];

  /// Set up the Total Data Chart

  public chartType: ChartType = 'bar';
  public chartData: ChartData<'bar'> = {
    labels: [],
    datasets:[]
  }; 

  public chartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
      y: {
        stacked: false,
        ticks: {
          
        }
      },
      x: {
        stacked: false
      }
    },
      
    plugins: {
      datalabels: {
        formatter: Math.round,
        color: (context: any) => '#FFFFFF',
        //title: false,
        display: function (context) {
          return Number(context.dataset.data[context.dataIndex]) >= 0;
        },
      },
      title: {
        display: false,
        //fontSize: 14,
        text: [],
        //align: 'left'
      },
      legend: {
        display: false,
      },

    }
  }

  public chartReady = false;



  constructor(
    private yearOverYearService: YearOverYearService
  ) { }


  //init
  ngOnInit() {

    this.chartOptions.plugins.datalabels.color = (context: any) => {
      const c = this.chartColors[context.datasetIndex];
      let value = '#FFFFFF';
      if (c) {
        value = this.lightOrDark(c.backgroundColor);
      }
      return value;
    };
    this.chartOptions.plugins.title.text = [this.title, 'Total Orders Month-on-Month'];
    //this.chartData.labels = ['2021 Total', '2021 YTD', '2022 YTD']; // this.rawData.map(i => i.label);
    this.chartData = this.rawData;
    //this.chartData.labels = this.chartData.datasets[0].label
    this.chartReady = true;

    //console.log("RawData:", this.rawData);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.chartData = this.rawData;
    
  }

  lightOrDark(color): string {
    // Variables for red, green, blue values
    let r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

      r = color[1];
      g = color[2];
      b = color[3];
    }
    else {

      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace(
        color.length < 5 && /./g, '$&$&'));

      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp < 155.5) {

      return '#FFFFFF';
    }
    else {

      return '#000000';
    }
  }
}
