<div class="chart-container" *ngIf="chartReady">
  <div style="font-weight: bold; color: #767676">{{title}}</div>
  <div style="margin-bottom:6%; color: #767676; min-width: 160px;">Total Orders <br /> Year-on-Year</div>
  <!-- <div class="row">
    <div class="col-sm-10"> -->
  <canvas baseChart height="780" style="min-width: 160px;" class="total-data-char" 
    [data]="chartData"
    [labels]="chartData.labels"
     [colors]="defaultChartColors" 
     [options]="chartOptions" 
    [chartType]="chartType"
    [plugins]="labelChartPlugins">
  </canvas>

  <!-- </div>
    <div class="col-sm">
      
    </div>
  </div> -->

</div>