import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-top-sellers',
  templateUrl: './top-sellers.component.html',
  styleUrls: ['./top-sellers.component.css']
})
export class TopSellersComponent implements OnInit {
  @Input() customerKey: string;
  @Output() updateHeight = new EventEmitter<string>();
  public records: any;
  public style: string;
  public area: string;
  public isLoaded = false; 
  public resp: any;
  public dateRange: number;
  public arr: any;

  constructor(private dashboardService: DashboardService) { }

  ngOnInit() {
    const apiPath = 'sales/topsellers/';
    this.dashboardService.getApiData(this.customerKey, apiPath).subscribe((response) => {
      this.dateRange = 365;

      this.arr = response;

      this.resp = this.arr.sort(function (a,b) {
           return b.productTotal - a.productTotal;
         });

       

      // Build object of top-sellers data
      this.records = {
        productLines: []
      };

      for (const x of [...new Set(this.resp.map((prod: { productLine: string; }) => prod.productLine.toUpperCase()))]) {
        this.records.productLines.push(new ProductLine(x, this.resp, this.dateRange));
      }
    
      if (this.records.productLines.length > 0) {
        this.switchStyle(this.records.productLines[0].name);
      }
      this.area = "REGIONAL";
      this.isLoaded = true;
      this.updateHeight.emit("Update");
    });

  }

  switchStyle(style: string) {
    this.style = style;
  }

  getStyleNumber(style: string) {
    let i: number;
    for (i = 0; i < this.records.productLines.length; i++) {
      if (this.records.productLines[i].name === style) {
        return i;
      }
    }
    return 0;
  }

  onClick(a: string) {
    this.area = a;
  }

  dateChange(a: number) {
    this.dateRange = a;
    this.records = {
      productLines: []
    };
    this.resetProducts();
    
  }

  resetProducts() {
    for (const x of [...new Set(this.resp.map((prod: { productLine: string; }) => prod.productLine.toUpperCase()))]) {
      this.records.productLines.push(new ProductLine(x, this.resp, this.dateRange));
    }
  }

  /* orderNow(productName: string) {
    return "https://retailers.maggiesottero.com/Product/KeywordSearch?keyword=" + productName
  } */
}

// Product object template
function Product(product: any, type: string, style: string, resp: any, dateRange: number) {
  this.name = product.productName;
  this.dateRange = dateRange;
  // Creates an array of all color versions of this product
  this.productColors = resp.filter(x => (x.section === type && x.productLine.toUpperCase() === style && x.productName === this.name && x.days === this.dateRange));
  this.topColor = this.productColors[0].topColor;
  this.imageUrl = this.productColors[0].imageUrl;
  this.orderUrl = this.productColors[0].orderUrl;
  this.productTotal = this.productColors[0].productTotal;
}

// Section object template; should be Customer, Region, or Nation
function Section(type: string, style: string, resp: any, dateRange: number) {
  this.type = type;
  this.products = [];
  const tempProductNames = [];
  this.dateRange = dateRange;
  // Function to check for products that have duplicates due to multiple color versions
  function checkForDuplicates(x: any) {
    if (!tempProductNames.includes(x.productName)) {
      tempProductNames.push(x.productName);
      return true;
    } else {
      return false;
    }
  }
  // Build an array of all the products for this section
  const tempProducts = [...new Set(resp.filter(x => (x.days === this.dateRange && x.section === type && x.productLine.toUpperCase() === style && checkForDuplicates(x))))];
  for (const x of tempProducts) {
    this.products.push(new Product(x, type, style, resp, dateRange));
  }
}

// ProductLine object template; Should be Maggie Sottero, Sottero & Midgley, Rebecca Ingram, or Curve Styles
function ProductLine(name: any, resp: any, dateRange: number) {
  this.name = name;
  this.sections = {
    Customer: new Section('Customer', name, resp, dateRange),
    Nation: new Section('Nation', name, resp, dateRange),
    Region: new Section('Region', name, resp, dateRange),
    StateProvince: new Section('StateProvince', name, resp, dateRange)
  };
}
