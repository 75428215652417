<div *ngIf="chartMode == 'doughnut'" id="incentives" class="col-md-12">
  <app-spinner [visible]="!isLoaded"></app-spinner>
  <div class="title">MY CLUB GOALS: {{year}}</div>
  <div *ngIf="incentives?.length > 0 && isLoaded;else no_incentives">
    <div class="incentives">
        <div class="incentive" *ngFor="let incentive of incentives">
          <div class=legend>
              <div *ngFor="let color of incentive.legendColors" [ngStyle]="{'background-color': color.value}">{{color.name}}</div>
          </div>
          <!-- <h5 class="quantity">Total Sold: {{incentive.quantity}}</h5> -->
          <canvas id="1" class="chart" style="padding-top:.5rem" baseChart
            [data]="incentive.doughnutChartData"
            [datasets]="incentive.doughnutChartData.datasets"
            [type]="doughnutChartType"
            [options]="doughnutChartOptions"
            [labels]="doughnutChartLabels"
            [plugins]="chartPlugins">
          </canvas>
          <h4 class="level">{{getLevel(incentive)}}</h4>
        </div>
    </div>
  </div>
  <div class="incentive rewardPolicy">
    <h5>Click <a href="https://retailers.maggiesottero.com/Files/Policies_and_Documents" target="_blank">Here</a> for "Authorized Retailer Policies and Forms" and list of benefits</h5>
  </div>
</div>

<div *ngIf="chartMode == 'bar'" class="sub-title">MY CLUB GOALS: {{year}} <span *ngIf="incentives != null">Total Sold-{{incentives[0].quantity}}</span></div>
<div *ngIf="chartMode == 'bar'" class=legend-small>
  <div *ngFor="let color of statusColors" [ngStyle]="{'background-color': color.value, color:lightOrDark(color.value)}">{{color.name}}</div>
</div>
<div *ngIf="chartMode == 'bar' && incentives != null" id="incentives">
  <canvas id="2" class="chart" style="padding-top:.5rem" baseChart
    [data]="incentives[0].barChartData"
    [datasets]="incentives[0].barChartData.datasets"
    [type]="barChartType"
    [options]="barChartOptions"
    [labels]="barChartLabels"
    [plugins]="chartPlugins">
 </canvas>
  
</div>

<ng-template #no_incentives>
  <div *ngIf="isLoaded">
      <div class="title">No current Incentive Status...</div>
  </div>
</ng-template>