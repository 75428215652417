import { Component, Renderer2, ViewChild } from '@angular/core';
import { YearOverYearService } from '../services/year-over-year.service';
import { ActivatedRoute } from "@angular/router";
import { DashboardService } from 'src/app/services/dashboard.service';
import { environment } from 'src/environments/environment';
import { elementAt } from 'rxjs';


@Component({
  selector: 'dashboard-main',
  templateUrl: './dashboard-main.component.html',
  styleUrls: ['./dashboard-main.component.css']
})
export class DashboardMainComponent {
  public shown = 1;
  /* public maggieSottero: string = "maggie-sottero";
  public rebeccaIngram: string = "rebecca-ingram";
  public sotteroMidgley: string = "sottero-midgley";

  public maggieSotteroTitle: string = "Maggie Sottero";
  public rebeccaIngramTitle: string = "Rebecca Ingram";
  public sotteroMidgleyTitle: string = "Sottero Midgley"; */

  public customerKey: string;

  // make one data object with all this...
  public rawData:any[];
  public productLines: any[];
  public years: any;
  public yearCurrent: any = new Date(Date.now()).getFullYear();
  public yearNext: any = this.yearCurrent + 1;
  
  public ytdTotal: any;
  

  public yoyData: any[];
  public showAlert = false;
  public alertText = "";
  public sku = "";
  public isConfirmation = true;
  public orderResultText: any = "Your order is being processed.";
  private stopListening: Function;
  private retailerPortal: any;
  public isLoaded = false;
  public selectedArea = 0;
  public incentiveMode = "doughnut"

  //@ViewChild(DashboardService) yoyComponent: DashboardService;

  area:HTMLElement;

  public statusColors = [
    { name: 'SILVER', value: '#C6C3BA' },
    { name: 'GOLD', value: '#DBCC8F' },
    { name: 'PLATINUM', value: '#605F5F' },
    { name: 'DIAMOND', value: '#D9DEE8' },
    { name: 'BEYOND DIAMOND', value: '#F5F8FC'}
  ];

  public dashboardColors = [
    [
      { backgroundColor: '#41b3a3' },
      { backgroundColor: '#e27d60' }
    ],
    [
      { backgroundColor: '#fc4445' },
      { backgroundColor: '#55bcc9' }
    ],
    [
      { backgroundColor: '#f1c40f' },
      { backgroundColor: '#2c3e50' }
    ]
  ];

  constructor(private route: ActivatedRoute, private yearOverYearService: YearOverYearService, private renderer: Renderer2, private dashboardService: DashboardService) {
    this.stopListening = renderer.listen('window', 'message', this.handleMessage.bind(this));
  }

  ngOnInit() {
    console.log('in Dashboard-Main Init... Auth Guard?');
    if (this.route.snapshot.paramMap.get("key")) {
      this.customerKey = this.route.snapshot.paramMap.get('key');
    }
    else {
      this.customerKey = "";
    }

    // do we need this here?
    // This does not come back before YOY app component initializes
    // so it ends up getting called twice
    // this.yearOverYearService
    // .getProductLineDatabyCustomer(this.customerKey)
    // .subscribe((resp) => {
    //   this.productLines
    //   this.productLines = resp, this.productLines.unshift("");
    //   console.log("YoY product lines", resp.unshift(""));
    // });

    this.yearOverYearService.getYoYSalesData(this.customerKey).subscribe((response) => {
      //console.log("Dashboard Main calling YoYSalesData");
      this.yearOverYearService.yoySalesData = response;

      this.rawData = response;
      this.productLines = [...new Set(response.map(items => items.productLine))];
      this.years = response.map(item => item.year).filter((value, index, self) => self.indexOf(value) === index);

      this.ytdTotal = response.filter(item => item.year === this.yearCurrent).reduce((prev, cur) => prev + cur.quantity, 0);;

      //console.log("getYoYSalesData response:", response);
      this.isLoaded = true;
      //this.updateHeight();

      //var xpath = '//*[@id="areas"]/button[text()="All Brands"]';
      //this.area = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue as HTMLElement;
      //this.area.focus();

    });

  }

ngAfterViewInit() {
  this.updateHeight();
}

public toggleShownData(val: number) {
  this.shown = val;
}

  public showMe(val:number) {
    return val === this.selectedArea || this.selectedArea === 0;
  }

  public showMe2(ele:HTMLElement) {
    ele.focus();
  }

  private handleMessage(event: Event) {
    const message = event as MessageEvent;
    console.log("Dashboard Handle Message:" + message.origin);
    this.retailerPortal = (<any>event).source;
    this.retailerPortal.postMessage(document.documentElement.offsetHeight, "*");
  }

  public scroll(elem: any) {
    const element = document.getElementById(elem);
    if (element == null) {
      window.scroll(0, 0);
    }
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  /**
   * updateHeight
   * Update the height of the iframe when a component finishes loading
   */
  public updateHeight(event="Update") {
    if (this.retailerPortal) {
      console.log("Dashboard Post Update Height:");
      this.retailerPortal.postMessage(document.documentElement.scrollHeight, "*");
    }
  }

  lightOrDark(color): string {
    // Variables for red, green, blue values
    let r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

      r = color[1];
      g = color[2];
      b = color[3];
    }
    else {

      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace(
        color.length < 5 && /./g, '$&$&'));

      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp < 155.5) {

      return '#FFFFFF';
    }
    else {

      return '#000000';
    }
  }

}
