<div id="badges" class="col-md-12">
  <app-spinner [visible]="!isLoaded"></app-spinner>

  <div id="status">
    <div class="sub-title">MY CLUB STATUS: {{yearCurrent}}</div>
    <div class="mybadge" *ngIf="badges_active?.length > 0 && isLoaded;else no_badges" >
      <img *ngFor="let badge of badges_active" [src]="badge.imageUrl" [alt]="badge.premierLevel">
    </div> 
  </div>
  
  <div id="goals">
    
    <app-incentives #area [chartMode]="incentiveMode" [customerKey]="customerKey" [statusColors]="statusColors"> </app-incentives>
  </div>

  <ng-template #no_badges>
    <div *ngIf="isLoaded">
        <div>No current Club Status...</div>
    </div>
  </ng-template>

  <div *ngIf="badges_pending?.length > 0 && isLoaded">
    <div class="title_pending">MY PENDING CLUB STATUS: {{pending_year}}</div>
    <div class="badges">
      <div class="badge" *ngFor="let badge of badges_pending">
        <img [src]="badge.imageUrl" [alt]="badge.premierLevel">
      </div>
    </div>
  </div>
</div>