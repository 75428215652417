import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { Router } from '@angular/router';
import { authConfig } from './security/auth-config';
import { isPlatformBrowser } from '@angular/common';

import { Observable } from 'rxjs';
import { AuthService } from './security/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ms-dashboard-ui';
  
  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: Object) { }

 // isAuthenticated: Observable<boolean>;
  //isDoneLoading: Observable<boolean>;
 // canActivateProtectedRoutes: Observable<boolean>;
  
  // constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: Object, private oauthService: OAuthService) {
  //   if (isPlatformBrowser(this.platformId)) {
  //     this.oauthService.configure(authConfig);
  //     this.oauthService.tokenValidationHandler = new JwksValidationHandler();
  //     this.oauthService.loadDiscoveryDocumentAndTryLogin();
  //   }
  // }
  
  // constructor (
  //   private authService: AuthService,
  // ) {
  //   this.isAuthenticated = this.authService.isAuthenticated$;
  //   this.isDoneLoading = this.authService.isDoneLoading$;
  //   this.canActivateProtectedRoutes = this.authService.canActivateProtectedRoutes$;

  //   this.authService.runInitialLoginSequence().then(() => {
  //     console.log('Back from runInitialSequence');
  //   });

    


    ngOnInit() {
      console.log('in App Init...' + this.router.url)
      //if (isPlatformBrowser(this.platformId)) {

      // if ( this.authService.hasValidToken )
      // {
      //   console.log('in App Init with valid token : this.oathSerice.state = ' + this.authService.oauthService.state + ', this.router.url = ' + this.authService.router.url);

      //       // back to where we came from
      //   this.authService.router.navigateByUrl(this.authService.oauthService.state);

      // }

    //}

  }
}