<div id="rewards" class="col-md-12">
  <app-spinner [visible]="!isLoaded"></app-spinner>
  <div class="title">MY CLUB BENEFITS: {{ year }}</div>
  <div *ngIf="rewards?.length > 0 && isLoaded; else no_rewards">
    <div class="rewards">
      <div class="earnings">
        My Benefit Total: <span class="amount">{{ earned }}</span>
      </div>
      <table>
        <thead>
          <tr>
            <th>Benefits Category</th>
            <th style="text-align: right">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr class="reward" *ngFor="let reward of rewards">
            <td>{{ reward.category }}
              <span *ngIf="reward.misc">
                &nbsp;with&nbsp;<img
                  src="{{ reward.misc }}"
                  alt="Trees Planted"
                  class="reward__image"
                />
              </span>
            </td>
            <td align="right">{{ reward.total }}</td>
          </tr>
        </tbody>
      </table>
      <div class="discount">
       
      </div>
      <div *ngIf="margin != null">
        <div class="margin">
          {{ margin.category }} <span class="mamount">{{ margin.total }}</span>
        </div>
      </div>

      <div class="rewardPolicy">
        <h5>Benefits Criteria</h5>
        <div class="fineText"><span>Gown Discount</span> does not include the discounts applied to accessories, packages and fabric.</div>
        <div class="fineText"><span>One Tree Planted</span> tracked from July 15th, 2023.</div>
        <div class="fineText"><span>Gown Margin</span> does not include margin on accessories, packages, fabrics, or shipping.</div>
      </div>
    </div>
  </div>
  <ng-template #no_rewards>
    <div *ngIf="isLoaded">
      <div class="norewards">No current Club Benefits...</div>
    </div>
  </ng-template>
</div>
