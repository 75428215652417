import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { MonthYearProductLineSales, MonthYearProductLineSalesAdapter } from '../models/monthyearproductlinesales.model';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class YearOverYearService {

  private baseUrl = environment.apiUrl;
  public yoySalesData: MonthYearProductLineSales[];
  //public yoyClickData:MonthYearOutboundClicks[];

  constructor(
    private http: HttpClient,
    private adapterSales: MonthYearProductLineSalesAdapter,
    //private adapterClicks: MonthYearOutboundClicksAdapter,
    //private oauthService: OAuthService
  ) { }

  getYoYSalesData(key: string): Observable<MonthYearProductLineSales[]> {
    //const clientId = key ? key : this.getClientIdIdentityClaim();
    //if (!clientId) { return; }
    const url = `${this.baseUrl}` + 'sales/yoyRawData/' + key;
    return this.http.get(url).pipe(
      map((data: any[]) => data.map(item => this.adapterSales.adapt(item))),
      catchError(this.handleError('error getting getYoY Sales Data data ', []))
    );
  }

  // getYoYClickData(key:string, apiPath:string): Observable<MonthYearOutboundClicks[]> {
  //   //console.log("getYoyClickData API call!");
  //   const clientId = key ? key : this.getClientIdIdentityClaim();
  //   const url =`${this.baseUrl}` + apiPath + clientId;;
  //   return this.http.get(url).pipe(
  //     map((data: any[]) => data.map(item => this.adapterClicks.adapt(item))),
  //     catchError(this.handleError('error getting getYoY Click data ', []))
  //   );
  // }



  getProductLineDatabyCustomer(key: string): Observable<string[]> {
    // console.log("getProductLineDatabyCustomer API call!");

    this.getYoYSalesData(key).subscribe(res => {
      this.yoySalesData = res;

    });

    return this.getYoYSalesData(key).pipe(map((data: any[]) => data.map(item => item.productLine)), map(m => m.filter(this.onlyUnique)),
      catchError(this.handleError('error getting getYoYData productLine data ', [])));

    /* return this.http.get(url).pipe(
      map((data: any[]) => data.map(item => item.productLine)),
      map(m => m.filter(this.onlyUnique)),
      catchError(this.handleError('error getting getYoYData productLine data ', []))
    ); */
  }

  // getClickDatabyCustomer(key:string): Observable<string[]> {
  //   this.getYoYData2(key,'').subscribe(res => {
  //     this.yoyClickData = res;
  //   })
  // }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  //returns the current client id claim
  // private getClientIdIdentityClaim() {
  //   const claims = this.oauthService.getIdentityClaims();
  //   return (!claims) ? "" : claims["customer_key"];
  // }

  private onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

}
