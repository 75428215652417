import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './security/app-routing.module';
//import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppComponent } from './app.component';
import { NgChartsModule } from 'ng2-charts';
import { AllbrandsonlyComponent } from './components/allbrandsonly/allbrandsonly.component';
import { NavigationComponent } from './components/shared/navigation/navigation.component';
import { TokenInterceptor } from './security/token-interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OAuthService, UrlHelperService, OAuthLogger, DateTimeProvider, SystemDateTimeProvider } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthGuard } from './security/auth-guard';
import { createDefaultLogger } from './factories';
import { CallbackComponent } from './security/callback.component';
import { YearOverYearComponent } from './components/year-over-year/year-over-year.component';
import { TotalChartDataComponent } from './components/year-over-year/total-data-chart/total-data-chart.component';
import { SpinnerComponent } from './components/shared/spinner/spinner.component';
import { DashboardMainComponent } from './dashboard-main/dashboard-main.component';
import { BadgesComponent } from './components/badges/badges.component';
import { IncentivesComponent } from './components/incentives/incentives.component';
import { RebatesComponent } from './components/rebates/rebates.component';
import { RewardsComponent } from './components/rewards/rewards.component';
import { TopSellersComponent } from './components/top-sellers/top-sellers.component';
import { ReplacementsComponent } from './components/replacements/replacements.component';
import { OutboundComponent } from './components/outbound/outbound.component';
import { AuthModule } from './security/auth.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    AppComponent,
    AllbrandsonlyComponent,
    DashboardMainComponent,
    NavigationComponent,
    CallbackComponent,
    YearOverYearComponent,
    SpinnerComponent,
    TotalChartDataComponent,
    BadgesComponent,
    IncentivesComponent,
    RebatesComponent,
    RewardsComponent,
    TopSellersComponent,
    ReplacementsComponent,
    OutboundComponent
  ],
  imports: [
    BrowserModule,
    //AuthModule.forRoot(),
    AppRoutingModule,
    //RouterModule,
    NgChartsModule,
    HttpClientModule,
    NgbModule,
    //OAuthModule.forRoot(),
    //FontAwesomeModule,
  ],
  providers: [
    //OAuthService,
    //{ provide: OAuthLogger, useFactory: createDefaultLogger },
    //{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    //JwksValidationHandler,
    //UrlHelperService,
    //AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
