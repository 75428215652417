<div id="outbounds" class="col-md-12">
  <app-spinner [visible]="!isLoaded"></app-spinner>
  <div class="title">MY REFERRALS FROM MAGGIE SOTTERO {{clientUrl}}</div>
  <div *ngIf="lineChartData.datasets.length > 0 && isLoaded; else no_clicks">
    <div class="outbounds-row"> 
      <div id="outbound-mom">
          <div id="dataLegend">
              <div id="dataTitle">MY REFERRAL CLICKS BY MONTH</div>
              <div id="legendSpacer">
                  <div *ngFor="let label of legendList" [ngStyle]="{'background-color': label.color, 'color': label.fontColor }">
                      {{label.name}}
                  </div>
              </div>
          </div>
          <div class="chartContainer">
            <canvas baseChart
              [data]="lineChartData"
              
              [labels]="lineChartData.labels"
              [options]="lineChartOptions"
              
              [type]="lineChartType"
              [plugins]="labelChartPlugins">
            </canvas>
          </div>
      </div>
      <div id="outbound-total">
          <div id="dataTotalTitle">MY TOTAL REFERRAL CLICKS</div>
          <div class="chartContainer">
            <canvas baseChart
              [data]="barChartData"
              [labels]="barChartData.labels"
              [options]="barChartOptions"
              [type]="barChartType"
              [plugins]="labelChartPlugins">
            </canvas>
          </div>
      </div>
    </div>
  </div>
  <ng-template #no_clicks>
      <div *ngIf="isLoaded">
          <div class="title">No current Outbound clicks recorded...</div>
      </div>
  </ng-template>
</div>