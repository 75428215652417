import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.css']
})
export class RewardsComponent implements OnInit {
  @Input() customerKey: string;
  @Output() updateHeight = new EventEmitter<string>();
  public records: any;
  public rewards: any;
  public year: number;
  public earned: string;
  public discount: string;
  public margin: any;
  private ismargin: any;
  public isLoaded = false;

  constructor(private dashboardService: DashboardService) { }

  ngOnInit() {
    const apiPath = 'sales/rewards/';
    this.dashboardService.getApiData(this.customerKey, apiPath).subscribe((response) => {

      
      this.records = response;
      if (this.records.length == 0) {
        this.isLoaded = true;
        return;
      }

      this.rewards = this.records.filter(row => row.sequence < 9000);
      this.earned = this.records.filter(row => row.category === 'EARNINGS')[0].total;
      this.discount = this.records.filter(row => row.category === 'DISCOUNT')[0].total;
      this.year = this.records[0].rewardYear;

      // 'Extra Margin on Re-Order Gowns'
      this.ismargin = this.records.filter(row => row.sequence === 9998);


      if (this.ismargin != null) {
        this.margin = this.ismargin[0];
      }
      else {
        this.margin = null;
      }

      this.isLoaded = true;
      this.updateHeight.emit("Update");
    });

  }
  calcDiscount(): string {
    return '0';

  }

  calcTotalEarned(): string {

    return '0';

  }



}
