import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CallbackComponent } from './callback.component';
import { AllbrandsonlyComponent } from '../components/allbrandsonly/allbrandsonly.component';
import { DashboardMainComponent } from '../dashboard-main/dashboard-main.component';
import { AuthGuard } from './auth-guard.service';
import { AppComponent } from '../app.component';

const routes: Routes = [
  {
    path: 'iframe',
    //canActivate: [AuthGuard],
    component: AppComponent,
    children: [
      // {
      //   path: '',
      //   component: AllbrandsonlyComponent,
      //   pathMatch: 'full'
      // },
      // {
      //   path: 'charts/dashboard',
      //   component: DashboardMainComponent,
      // },
      // {
      //   path: 'allbrandsonly',
      //   component: AllbrandsonlyComponent,
      // },
      {
        path: 'allbrandsonly/:key',
        component: AllbrandsonlyComponent,
      },
      {
        path: 'charts/dashboard/:key',
        component: DashboardMainComponent,
      },
      {
        path: 'charts/dashboard-card/:key',
        component: DashboardMainComponent,
      },
    ]
  },
  {
    path: 'auth-return',
    component: AppComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    //MainContainerRoutingModule,
    //IframeContainerRoutingModule,
    //MainContainerRoutingModule,
//IframeContainerRoutingModule,
RouterModule.forRoot(routes),
    [RouterModule.forChild(routes)]
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
