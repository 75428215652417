import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-replacements',
  templateUrl: './replacements.component.html',
  styleUrls: ['./replacements.component.css']
})
export class ReplacementsComponent implements OnInit {
  @Input() customerKey: string;
  @Output() updateHeight = new EventEmitter<string>();
  public alertText: any;
  public confirmationImageUrl: string;
  public confirmationName: string;
  public confirmationExpirationDate: string;
  public confirmationColors: any;
  public confirmationSizes: any;
  public filter: any;
  public confirmation: any;
  public confirmationSku: string;
  public isLoaded = false;
  public records: any;
  public style: string;
  public resp: any;
  public targetElement: any;
  public isConfirmed = false;

  constructor(private dashboardService: DashboardService) { }

  ngOnInit() {
    const apiPath = 'sales/samples/';
    this.dashboardService.getApiData(this.customerKey, apiPath).subscribe((response) => {
      this.resp = response;

      // Build object of replacements data
      this.records = {
        productLines: []
      };

      for (const x of [...new Set(this.resp.map((prod: { productLine: string; }) => prod.productLine.toUpperCase()))]) {
        this.records.productLines.push(new ProductLine(x, this.resp));
      }

      if (this.records.productLines.length > 0) {
        this.switchStyle(this.records.productLines[0].name);
        this.isLoaded = true;
      }
      this.isLoaded = true;
      this.updateHeight.emit("Update");
    });
  }

  switchStyle(style: string) {
    this.style = style;
  }

  getStyleNumber(style: string) {
    let i: number;
    for (i = 0; i < this.records.productLines.length; i++) {
      if (this.records.productLines[i].name === style) {
        return i;
      }
    }
    return 0;
  }

  public scroll(elem: any) {
    const element = document.getElementById(elem);
    if (element == null) {
      window.scroll(0, 0);
    }
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  public placeOrder() {
    var colorsDropdown = document.getElementById('colors') as HTMLSelectElement;
    var sizesDropdown = document.getElementById('sizes') as HTMLSelectElement;
    if (colorsDropdown.selectedOptions[0].value !== "notSelected" && sizesDropdown.selectedOptions[0].value !== "notSelected") {
      this.dashboardService.orderReplacement(this.customerKey, this.confirmationName + "/" + this.confirmationSku + "/" + sizesDropdown.selectedOptions[0].value + "/" + colorsDropdown.selectedOptions[0].value).subscribe((response) => {
        this.alertText = response;
      });
      this.alertText = "Processing Order";
      this.isConfirmed = true;
    } else {
      if (colorsDropdown.selectedOptions[0].value == "notSelected") {
        colorsDropdown.classList.add("needSelected");
      } else {
        colorsDropdown.classList.remove("needSelected");
      }
      if (sizesDropdown.selectedOptions[0].value == "notSelected") {
        sizesDropdown.classList.add("needSelected");
      } else {
        sizesDropdown.classList.remove("needSelected");
      }
    }
  }

  onClick(name: string, sku: string, expirationDate: string, imageUrl: string, colors: any, sizes: any) {
    var colorsDropdown = document.getElementById('colors') as HTMLSelectElement;
    var sizesDropdown = document.getElementById('sizes') as HTMLSelectElement;
    if (colorsDropdown) {
      colorsDropdown.classList.remove("needSelected");
      colorsDropdown.selectedIndex = 0;
    }
    if (sizesDropdown) {
      sizesDropdown.classList.remove("needSelected");
      sizesDropdown.selectedIndex = 0;
    }
    this.confirmationName = name;
    this.confirmationSku = sku;
    this.confirmationImageUrl = imageUrl;
    this.confirmationExpirationDate = expirationDate;
    this.confirmationColors = colors;
    this.confirmationSizes = sizes;
    this.alertText = "This will send a request for a sample replacement order for " + name + " to our Concierge team. <br> <br> We suggest checking inventory for a color and size in stock so your sample replacement will ship immediately!";
    this.isConfirmed = false;
    this.confirmation = document.getElementById("confirmation");
    this.filter = document.getElementById("filter");
    this.scroll("confirmation");
    this.confirmation.style.zIndex = "101";
    this.filter.style.zIndex = "100";
    this.confirmation.style.opacity = "100%";
    this.filter.style.opacity = "70%";
  }

  closeClick() {
    this.confirmation.style.opacity = "0%";
    this.filter.style.opacity = "0%";
    this.confirmation.style.zIndex = "-1";
    this.filter.style.zIndex = "-1";
  }

  onChange(event: any) {
    event.srcElement.classList.remove("needSelected");
  }

}

// Product object template
function Product(product: any, style: string, resp: any) {
  this.name = product.productName;
  this.sku = product.productSku;
  // Creates an array of all color versions of this product
  this.productColors = resp.filter(x => (x.productLine.toUpperCase() === style && x.productName === this.name));
  this.topColor = this.productColors[0].topColor;
  this.imageUrl = this.productColors[0].imageUrl;
  this.orderUrl = this.productColors[0].orderUrl;
  this.productTotal = this.productColors[0].productTotal;
  this.expirationDate = this.productColors[0].expirationDate;
  this.sampleDate = this.productColors[0].sampleDate;
  this.colors = this.productColors[0].colors;
  this.sizes = this.productColors[0].sizes;
}

// ProductLine object template; Should be Maggie Sottero, Sottero & Midgley, Rebecca Ingram, or Curve Styles
function ProductLine(name: any, resp: any) {
  this.name = name;
  this.products = [];
  // Build an array of all the products for this section
  const tempProducts = [...new Set(resp.filter(x => (x.productLine.toUpperCase() === this.name)))];
  for (const x of tempProducts) {
    this.products.push(new Product(x, this.name, resp));
  }
}
