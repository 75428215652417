import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { MonthYearProductLineSalesAdapter } from '../models/monthyearproductlinesales.model';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private baseUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private adapter: MonthYearProductLineSalesAdapter
    //private oauthService: OAuthService
  ) { }

  /////////////////////////////////////////////////////////////////////////
  // Generic get data from the Dashboard API, calling component passes path
  // and passes customer key, TODO can we set clientId globally here and
  // components dont need to care?
  /////////////////////////////////////////////////////////////////////////
  getApiData(key: string, path: string) {
    //const clientId = key ? key : this.getClientIdIdentityClaim();
    const url = `${this.baseUrl}` + path + key;
    return this.http.get(url).pipe(
      catchError(this.handleError('error getting data from: ', [path]))
    );
  }

  // need a specific function to request sample replacements
  // TODO - should this be a get or put, and can we still get confirm/error message from API?
  orderReplacement(key: string, name: string) {
    //const clientId = key ? key : this.getClientIdIdentityClaim();
    const url = `${this.baseUrl}` + 'sales/ordersample/' + key + "/" + name;
    return this.http.get(url).pipe(
      catchError(this.handleError('error getting data from: ', "Error Placing Order"))
    );
  }

  //returns the current client id from the oauth identity claim
  // private getClientIdIdentityClaim() {
  //   const claims = this.oauthService.getIdentityClaims();
  //   return (!claims) ? "" : claims["customer_key"];
  // }

  // outputs any errors to console
  // TODO - log options?
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

}
