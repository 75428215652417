import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Chart, ChartData, ChartDataset, ChartOptions, ChartType, Color } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import ChartDataLabels  from 'chartjs-plugin-datalabels';
//import { MonthYearProductLineSalesAdapter } from 'src/app/models/monthyearproductlinesales.model';

@Component({
  selector: 'app-outbound',
  templateUrl: './outbound.component.html',
  styleUrls: ['./outbound.component.css']
})
export class OutboundComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  
  @Input() customerKey: string;
  @Output() updateHeight = new EventEmitter<string>();
  public records: any;
  public clientUrl: string;
  public yearsList: any = [];
  public legendList = [];
  public isLoaded = false;

  public labelChartPlugins = [ChartDataLabels];
  public monthList: any;  // month is a revolving year

  constructor(private dashboardService: DashboardService) { }

  

  //////////////////////////////////////////////
  /// Set up the Line chart 
  ///////////////////////////////////////////////
  public lineChartData: ChartData<'line'> = {
    labels:[],
    datasets:[]
  }; 

  public lineChartType: ChartType = 'line';
  public lineChartLabels: string[] = [];
  public lineChartOptions: ChartOptions = {
    
    //scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      // y: {
      //   ticks: {
      //     beginAtZero: true
      //   }
    //  }
    },
    elements: {
      line: {
        tension: 0.5
      }
    },
   
    plugins: {
      datalabels: {
        formatter: Math.round,
        color: (context: any) => '#000000',
        //title: false,
        display: function (context) {
          return Number(context.dataset.data[context.dataIndex]) > 0;
        },
        align: 'end'
      },
      legend: {
        display: false,
        position: 'top',
        align: 'start',
      },
      title: {
        display: false,
        //fontSize: 18,
        //fontColor: '#767676',
        //fontfamily: 'proxima-nova',
        text: ["MY OUTBOUND CLICKS BY MONTH"],
        //align: 'left'
      }
    }
  }

  ////////////////////////////////////
  /// Set up the bar chart
  ////////////////////////////////////
  public barChartData: ChartData<'bar'> = {
    labels:[],
    datasets:[]
  };

  public barChartType: ChartType = 'bar';
  public barChartLabels: string[] = [];
  
  public barChartOptions: ChartOptions = {
    //scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        // y: [{
        //   // ticks: {
        //   //   beginAtZero: true
        //   // }
        // }],
        x: {
            
          //scaleLabel: {
            //display: true,
            //labelString: '2020                   2021 YTD',
            // padding: {
            //   top: -20
            // }
          }
        //   // ticks: {
        //   //   beginAtZero: true
        //   // }
        // }]
    },
    // tooltips: {
    //   callbacks: {
    //     title: function (tooltipItem) {
    //       return "Total";
    //     }
    //   }
    
    plugins: {
      datalabels: {
        formatter: Math.round,
        color: (context: any) => '#000000',
        //title: false,
        display: function (context) {
          return Number(context.dataset.data[context.dataIndex]) > 0;
        }
      },
      title: {
        display: false,
        //fontSize: 18,
        //fontColor: '#767676',
        //fontfamily: 'proxima-nova',
        text: ["MY TOTAL OUTBOUND CLICKS"],
        //align: 'left'
      },
      legend: {
        display: false,
        position: 'top',
        //align: 'right'
      }
    }
  }

  public chartColors: string[] = ['rgb(255, 215, 98)','rgb(56, 168, 190)'];
  public fontColors: string[]= ['#6A6A6A','white'];

  public defaultChartColors: Array<any> = [
    {
      backgroundColor: '#ffd762',
      borderColor: '#ffd762',
      pointBorderColor: '#fff',
    },
    {
      backgroundColor: '#38a8be',
      borderColor: '#38a8be',
      pointBorderColor: '#fff',
    }
  ];

  //public barChartData2 = [];
  ngOnInit() {

    const apiPath = 'sales/outbound/';

    /// this comes back formated by adapter
    // this.yearOverYearService.getYoYClickData(null, apiPath).subscribe((response) => {

    //   this.monthYearOutboundClickList = response;
    //   console.log('Outbound YoY service Data', response);
    //   //this.formatChartInformation();

    // });

    //this comes back raw data from API
    this.dashboardService.getApiData(this.customerKey, apiPath).subscribe((response) => {

      this.records = response;
      this.formatChartInformation();
      this.isLoaded = true;
      this.updateHeight.emit("Update");
    });

  }



  //////////////////////////////////////
  /// Parse it up by year and month
  /////////////////////////////////////
  formatChartInformation() {

    //get the client's URL from top of the list
    if (this.records.length > 0 ) {
      this.clientUrl = this.records[0].placeURL;
    }

    //get unique list of years, order 
    this.yearsList = [...new Set(this.records.map(r => r.year))];

    //get the list of Months, this should start with current month
    this.monthList = [...new Set(this.records.map(r => r.monthName))];

    // get current year
    let yearCurrent = new Date().getFullYear();
    
    // data for Month on month
    let dataTotals = []; //data for year totals

    let x = 0;
    for (let year of this.yearsList) {
      //let yearData = [];
      let monthData = [];
      // due to revolving year we have to query data by year and month
      for ( let month of this.monthList) {

        // query records by month and year
        let monthClicks = this.records.filter(item => (item.year === year && item.monthName === month)).map(col => col.quantity);
        if (monthClicks[0] !== -1) {
          monthData.push(monthClicks[0]);
        } else {
          monthData.push(null);
        }
      }

      //sum the quantity
      let sum = monthData.reduce((a, b) => a + b, 0);
      //let isCurrentYear = year == yearCurrent;

      let yearData:ChartDataset<'line'> = {
        label:year,
        data:monthData,
        backgroundColor:this.chartColors[x],
        borderColor:this.chartColors[x],
        pointBorderColor: '#fff'
      };

      this.lineChartData.datasets.push(yearData);
      this.legendList.push({ name: year, color: this.chartColors[x], fontColor: this.fontColors[x] });

      x++;
      
      // this doesn't work anymore!!
      //TODO
      //figure out if we are last year year in list
      // if (year == yearCurrent) {
        
      //   this.lineChart.labelsTotal.push(year + ' YTD');
      // }
      // else {
      //    this.lineChart.labelsTotal.push(year);
      // }

      dataTotals.push(sum)
    }
    
    this.lineChartData.labels = this.monthList;
    this.barChartData.datasets.push({ data: dataTotals, backgroundColor: this.chartColors });
    this.barChartData.labels = this.yearsList;

  }

}
