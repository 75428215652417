<div class="container-fluid">
  <div class="title">My Maggie Club Dashboard - {{customerKey}}</div>
  <app-badges [customerKey]="customerKey" [statusColors]="statusColors" (updateHeight)="updateHeight($event)"></app-badges>
</div>

<div class="container-fluid scroll-dashboard">

  <div id="areas" class="row">
    <input id="btnAll" class="btn-check" type="radio" name="btnradio" autocomplete="off" (click)="selectedArea = 0" checked>
    <label class="btn btn-outline-maggie" for="btnAll">All</label>
    <input id="btnAllBrands" class="btn-check" type="radio" name="btnradio" value="1" autocomplete="off" (click)="selectedArea = 1" >
    <label class="btn btn-outline-maggie" for="btnAllBrands">All Brands</label>

    <div *ngFor="let opt of productLines; let i = index">
      <input [attr.id]="opt" class="btn-check" type="radio" name="btnradio" [attr.value]="++i + 2" autocomplete="off" (click)="selectedArea = ++i + 2" >
      <label class="btn btn-outline-maggie" [attr.for]="opt">{{opt}}</label>
    </div>

    <input id="btnRewards" class="btn-check" type="radio" name="btnradio" autocomplete="off" (click)="selectedArea = 6" >
    <label class="btn btn-outline-maggie" for="btnRewards">Benefits</label>
    <input id="btnSellers" class="btn-check" type="radio" name="btnradio" autocomplete="off" (click)="selectedArea = 7" >
    <label class="btn btn-outline-maggie" for="btnSellers">Top Sellers</label>
    <input id="btnSamples" class="btn-check" type="radio" name="btnradio" autocomplete="off" (click)="selectedArea = 8" >
    <label class="btn btn-outline-maggie" for="btnSamples">Sample Replacements</label>
    <input id="btnReferals" class="btn-check" type="radio" name="btnradio" autocomplete="off" (click)="selectedArea = 9" >
    <label class="btn btn-outline-maggie" for="btnReferals">Referrals</label>
    
  
  </div>
    
  <div #area id="yoy" name="yoy" class="col-md-12" style="min-height: 7em;" *ngIf="selectedArea < 5">
      <!-- <div class="row"> -->
        <app-spinner [visible]="!isLoaded"></app-spinner>
        <div class="title">MY ORDERS MONTH-ON-MONTH</div>
        <div *ngIf="productLines?.length > 0 && isLoaded; else no_orders">
          <div class="dropdown">
            <div class="dropdown-content">
              <form class="switch-field" id="orderField">
                <input type="radio" id="radioTotal" name="switch-two" value="yes" checked (click)="shown = 1"/>
                <label for="radioTotal">Total Orders</label>
        
                <input type="radio" id="radioSamples" name="switch-two" value="maybe"  (click)="shown = 2"/>
                <label for="radioSamples">Samples</label>
        
                <input type="radio" id="radioReOrders" name="switch-two" value="no"  (click)="shown = 3"/>
                <label for="radioReOrders">Re-Orders</label>
        
              </form>
            </div>
          </div>  

          <div [attr.id]="product" class="col-md-12" *ngIf="productLines.length > 1 && showMe(1)">
            <app-year-over-year [shown]="shown" [productLine]="" [title]="" [chartColors]="dashboardColors[1]"
              [customerKey]="customerKey" (updateHeight)="updateHeight($event)">
            </app-year-over-year>
          </div>
          
            <div [attr.id]="product" class="col-md-12" *ngFor="let product of productLines; let i = index">
                <app-year-over-year *ngIf="showMe(++i + 2)" [shown]="shown" [productLine]="product" [title]="product" [chartColors]="dashboardColors[i]"
                  [customerKey]="customerKey" (updateHeight)="updateHeight($event)">
                </app-year-over-year>
              </div>
        </div>
        <ng-template #no_orders>
            
            <div *ngIf="isLoaded">
                <div class="title">No Month-on-Month Orders</div>
            </div>
        </ng-template>
      <!-- </div> -->
  </div>
  
  <app-rewards [customerKey]="customerKey" (updateHeight)="updateHeight($event)" *ngIf="showMe(6)"></app-rewards>
  <app-top-sellers [customerKey]="customerKey" (updateHeight)="updateHeight($event)" *ngIf="showMe(7)"></app-top-sellers>
  <app-replacements [customerKey]="customerKey" (updateHeight)="updateHeight($event)" *ngIf="showMe(8)"></app-replacements>
  <app-outbound [customerKey]="customerKey" (updateHeight)="updateHeight($event)" *ngIf="showMe(9)"></app-outbound>
  
</div>