import { Component, OnChanges, OnInit, Input, Output,EventEmitter, SimpleChanges, ViewChild } from '@angular/core'
import { YearOverYearService } from '../../services/year-over-year.service';
import { MonthYearProductLineSales } from "../../models/monthyearproductlinesales.model";

import { ChartConfiguration, ChartData, ChartDataset, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-year-over-year',
  templateUrl: './year-over-year.component.html',
  styleUrls: ['./year-over-year.component.css']
})
export class YearOverYearComponent implements OnInit, OnChanges {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective | undefined;
  
  @Input() shown: number;
  @Input() productLine: string;
  @Input() title: string;
  @Input() chartColors: Array<any>;
  @Input() customerKey: string;
  @Input() allBrandsOnly: boolean;

  @Output() updateHeight = new EventEmitter<string>();

  
  public isLoaded = false;
  public totalData: ChartData<'bar'> = {labels:[], datasets:[]};
  yearsList: any;

  public YearOverYearData: MonthYearProductLineSales[];
  public monthYearProductLineSalesList: MonthYearProductLineSales[];

  public labelChartPlugins = [DataLabelsPlugin];
  public chartColors2:Array<any> = [];
  public totalColors:Array<any> = [];

  constructor( private yearOverYearService: YearOverYearService) { }

  //combobox
  public selectedValue = 1;

  //chart colors
  

  public monthArray: any = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
  
  ////////////////////////////////////
  ///setup the MOM chart
  /////////////////////////////////////
  public chartTitle: string;
  public chartType: ChartType = 'bar';
  public chartData: ChartData<'bar'> = {
    labels: this.monthArray,
    datasets:[]
  }; 

  public chartOptions: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {
        stacked: false,
        
      },
      y: {
        stacked: false,
        //max: Math.ceil(),
        ticks: {

        }
        //min: 10
      }
    },
    plugins: {
      
      datalabels: {
        formatter: Math.round,
          color: (context: any) => '#000',
          //title: false,
          display:
          function (context) {
             return Number(context.dataset.data[context.dataIndex]) > 0;
           },
        anchor: 'end',
        align: 'end',
        clamp: true
      },
      title: {
        display: false,
        //fontSize: 20,
        text: [],
        //align: 'left'
      },
      legend: {
        display: false,
        position: 'top',
        //align: 'right',
      }
    }
  }

  public chartDataSet:ChartDataset<'bar'> = {
    label:'year',
    data:[],
    backgroundColor:'',
    borderColor:''
  };

  // deal with colors!!!#62615A
  public defaultChartColors: Array<any> = [
    { backgroundColor: '#62615A' },
    { backgroundColor: '#BCAE9E' },
    { backgroundColor: '#62615A' },
    { backgroundColor: '#BCAE9E' }
  ];

  public maggieSotteroColors: Array<any> = [
    { backgroundColor: '#F1E0C2' },
    { backgroundColor: '#DCB268' },
    { backgroundColor: '#F1E0C2' },
    { backgroundColor: '#DCB268' }


  ];

  public sotteroAndMidgleyColors: Array<any> = [
    { backgroundColor: '#D7D7D7' },
    { backgroundColor: '#9B9B9B' },
    { backgroundColor: '#D7D7D7' },
    { backgroundColor: '#9B9B9B' }

  ];

  public rebeccaIngramColors: Array<any> = [
    { backgroundColor: '#E8D4CF' },
    { backgroundColor: '#C59587' },
    { backgroundColor: '#E8D4CF' },
    { backgroundColor: '#C59587' }

  ];

  // TODO
// OK, we need to centralize all chart colors in a module
// this component shouldn't care and get the colors passed to it
// Change evertything to RGB. HEX seems to work in most cases still
// For now just make it work

public totalChartColors: Array<any> = [ '#62615A','#62615A','#BCAE9E' ];

public maggieSotteroTotalColors: Array<any> = ['rgb(241, 224, 194)','rgb(241, 224, 194)','rgb(220, 178, 104)' ];

public sotteroAndMidgleyTotalColors: Array<any> = ['rgb(215, 215, 215)','rgb(215, 215, 215)','rgb(155, 155, 155)' ];

public rebeccaIngramTotalColors: Array<any> = [ 'rgb(232, 212, 207)','rgb(232, 212, 207)','rgb(197, 149, 135)' ];

 

  //init
  ngOnInit() {
    if (!this.yearOverYearService.yoySalesData) {
      this.yearOverYearService.getYoYSalesData(this.customerKey).subscribe(response => {
        this.monthYearProductLineSalesList = response;
        this.formatChartInformation();
        this.updateHeight.emit("Update");
      });
    }
    else {
      this.monthYearProductLineSalesList = Object.assign([], this.yearOverYearService.yoySalesData);
      //this.formatChartInformation();
    }


    if (this.productLine) {
      this.productLine = this.productLine.replace("-", " ").toLowerCase();
    }

    if (!this.title) {
      this.title = "All Brands";
    }

    // going to have to fix for new charts.js
    if (this.title === "All Brands") {
      this.chartColors2 = this.defaultChartColors;
      this.totalColors = this.totalChartColors;
    } else if (this.title === "Maggie Sottero") {
      this.chartColors2 = this.maggieSotteroColors;
      this.totalColors = this.maggieSotteroTotalColors;
    } else if (this.title === "Rebecca Ingram") {
      this.chartColors2 = this.rebeccaIngramColors;
      this.totalColors = this.rebeccaIngramTotalColors;
    } else if (this.title === "Sottero Midgley") {
      this.chartColors2 = this.sotteroAndMidgleyColors;
      this.totalColors = this.sotteroAndMidgleyTotalColors;
    }

    if (this.chartColors2.length == 0) {
      this.chartColors2 = this.defaultChartColors;
      this.totalColors = this.totalChartColors;
    }


    this.chartOptions.plugins.title.text = [this.title, 'Total Orders Month-on-Month'];
    

    this.formatChartInformation();

    this.updateHeight.emit("Update");
  }

  //events
  updateSelectedValue(value: number) {
    this.selectedValue = value;
    this.formatChartInformation();
  }

  filter(value: any) {

  }

  //business logic
  formatChartInformation() {
    if (this.monthYearProductLineSalesList == undefined) {
      return;
    }
    const filteredList = this.filterBySelectedOption(this.monthYearProductLineSalesList);
    const formatedData = this.calculateUnitSoldMonthYear(filteredList);

      this.chartData.datasets = formatedData.data;
      if (this.shown === 1) {
      this.chartTitle = "Total Orders"
    } else if (this.shown === 2) {
      this.chartTitle = "Sample Orders"
    } else if (this.shown === 3) {
      this.chartTitle = "Re-Orders"
    } else {
      this.chartTitle = "Total Orders"
    }
    //this.totalData = formatedData.totalData;
    this.isLoaded = true;
    this.chart?.update();
  }

  filterBySelectedOption(list: MonthYearProductLineSales[]) {
    return (this.productLine) ?
      list.filter(row => row.productLine.toLowerCase() === this.productLine.toLowerCase()) :
      list;
  }

  getYearsToGroup(list: MonthYearProductLineSales[]) {
    return list.map(item => item.year)
      .filter((value, index, self) => self.indexOf(value) === index);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.formatChartInformation();
    
  }

  calculateUnitSoldMonthYear(list: MonthYearProductLineSales[]) {
    const data = [];
    let totalData = [];
    const currentMonthIndex = new Date().getMonth();
    this.yearsList = this.getYearsToGroup(list);  //should be in order

    const currentYear = new Date(Date.now()).getFullYear();

    let dataSoldByYear: any = [];
    let dataYearTotals = [];
    let dataYearLabels = [];

    // for each year, create MoM data, and the year Totals and YTD.
    this.yearsList.forEach((year,y) => {
      
      let unitsFilter:any;
      let unitsSold = 0;
      let ytdUnitsSold = 0;
      let yearUnitsSold = 0;

      let dataSoldByMonth: number[] = [];
      //const label = this.yearsList[i];
      

      // go through the months
      for (let m = 0; m < 12; m++) {
        const unitsSoldFilter = list.filter(item => item.year === year && item.month === (m + 1));

        //based on the selected Option, sum up the sale month by month
        // TODO use a fancy map/reduce to get this more dynamically
        switch (this.shown) {
          case 1:
            unitsSold = unitsSoldFilter.reduce((prev, cur) => prev + cur.quantity, 0);
            break;
          case 2:
            unitsSold = unitsSoldFilter.reduce((prev, cur) => prev + cur.samples, 0);
            break;
          case 3:
            unitsSold = unitsSoldFilter.reduce((prev, cur) => prev + cur.reOrders, 0);
          break;
        
          default:
            unitsSold = unitsSoldFilter.reduce((prev, cur) => prev + cur.quantity, 0);
            break;
        }

        // push to the month data, results from reduce should default to 0, TBD
        dataSoldByMonth.push(unitsSold);

        yearUnitsSold += unitsSold;
        if ( m <= currentMonthIndex)
            ytdUnitsSold += unitsSold;

        // // based on the year, increment year and YTD totals
        // if (year === currentYear)
        // {
        //   // we only care about YTD
        //   ytdUnitsSold += unitsSold;
        // }
        // else
        // {
        //   // we want Totals and Year to date till the current month
        //   yearUnitsSold += unitsSold;
        //   if ( m <= currentMonthIndex)
        //     ytdUnitsSold += unitsSold;
        // }

      }

      let chartColor = [this.chartColors2[y].backgroundColor];

      // push the month totals
      this.chartDataSet = {
        label: year,
        data:dataSoldByMonth,
        backgroundColor: chartColor,
        borderColor: chartColor
      }
      data.push(this.chartDataSet);

      // Now deal with totals
      // TODO - BA this is where we need to define each total data element
      // Right now it is passing in a single value for three datasets
      //dataSoldByYear.push([yearUnitsSold, ytdUnitsSold]);

      

      // push as a dataset, any styling needs to be done here
      if (currentYear === year)
      {
        dataYearTotals.push(ytdUnitsSold);
        dataYearLabels.push(year + ' YTD');
      }
      else
      {
        dataYearTotals.push(yearUnitsSold);
        dataYearLabels.push(year + ' Total');
        dataYearTotals.push(ytdUnitsSold);
        dataYearLabels.push(year + ' YTD');
      }
      
    });
    

    this.totalData = {
      labels: dataYearLabels,
      //label: dataYearLabels, 
      datasets: [ 
        {
          data: dataYearTotals,
          backgroundColor: this.totalColors,
          borderColor: this.totalColors
        }
      ]
    }
    
    
    return { data, totalData };
  }

  lightOrDark(color): string {
    // Variables for red, green, blue values
    let r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

      r = color[1];
      g = color[2];
      b = color[3];
    }
    else {

      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace(
        color.length < 5 && /./g, '$&$&'));

      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp < 155.5) {

      return '#FFFFFF';
    }
    else {

      return '#000000';
    }
  }
}
