import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'app-allbrandonly',
  templateUrl: './allbrandsonly.component.html',
  styleUrls: ['./allbrandsonly.component.css']
})
export class AllbrandsonlyComponent {

  public productLine: string;
  private stopListening: Function;
  private retailerPortal: any;

  public customerKey: string;

  constructor(private route: ActivatedRoute, private renderer: Renderer2, private dashboardService: DashboardService) {
    this.stopListening = renderer.listen('window', 'message', this.handleMessage.bind(this));
  }

  //init
  ngOnInit() {  
    console.log('in AllBrandsOnly Init... Route is ' + this.route.url);
    if (this.route.snapshot.paramMap.get("key")) {
      this.customerKey = this.route.snapshot.paramMap.get('key');
    }
    else {
      this.customerKey = "";
    }
  }

  onClick() {
    if (this.retailerPortal) {
      this.retailerPortal.postMessage("Click", "*");
    }
  }

  private handleMessage(event: Event) {
    this.retailerPortal = (<any>event).source;
  }

}
