<div id="top_sellers" class="col-md-12">
  <app-spinner [visible]="!isLoaded"></app-spinner>
  <div class="title">MY TOP RE-ORDERED GOWNS</div>
  <div *ngIf="records?.productLines.length > 0 && isLoaded; else no_top_sellers">
    <div class="dropdown">
      <button class="dropbtn">DATE RANGE: LAST {{dateRange}} DAYS</button>
      <div class="dropdown-content">
        <form class="switch-field" id="dateField">
          <input type="radio" id="radio30" name="switch-two" value="yes" checked (click)="dateChange(30)" />
          <label for="radio30">LAST 30 DAYS</label>

          <input type="radio" id="radio60" name="switch-two" value="maybe" checked (click)="dateChange(60)" />
          <label for="radio60">LAST 60 DAYS</label>

          <input type="radio" id="radio90" name="switch-two" value="no" checked (click)="dateChange(90)" />
          <label for="radio90">LAST 90 DAYS</label>

          <input type="radio" id="radio180" name="switch-two" value="no" checked (click)="dateChange(180)" />
          <label for="radio180">LAST 180 DAYS</label>

          <input type="radio" id="radio365" name="switch-two" value="no" checked (click)="dateChange(365)" />
          <label for="radio365">LAST 365 DAYS</label>
        </form>
      </div>
    </div> 
    <div class="top_sellers">
      <div class="styles">
        <div *ngFor="let line of records.productLines"
          [ngClass]="{'nav-highlight': style === line.name, 'nav-no-highlight': style != line.name }"
          (click)="switchStyle(line.name)">{{line.name}}</div>
      </div>
      <div class="top_gowns">
        <div class="storeLeft">
          <div class="topOrderTextLeft">
            TOP RE-ORDERED {{style}} <br> GOWNS IN MY STORE
          </div>
          <div class="productsContainer"
            *ngIf="records.productLines[getStyleNumber(style)].sections.Customer.products?.length > 0; else no_products">
            <div class="products"
              *ngFor="let product of records.productLines[getStyleNumber(style)].sections.Customer.products">
              <div class="productName">{{product.name}}</div>
              <div class="myStoreProductInfoContainer">
                <a href="{{product.orderUrl}}" target="_blank">
                  <div class="myStoreImageContainer"><img [src]="product.imageUrl" class="leftImage"></div>
                </a>
                <div class="productWrittenInfoContainer">
                  <div class="productInfo" *ngFor="let color of product.productColors">
                    <div>{{color.color}}</div>
                    <div>{{color.quantity}}</div>
                  </div>
                  <div class="total">
                    <div>Total Reorders</div> 
                    <div>{{product.productTotal}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="storeRight">
          <div class="topOrderTextRight" *ngIf="area === 'REGIONAL';">
            TOP RE-ORDERED {{style}} <br> GOWNS IN MY REGION
          </div>
          <div class="topOrderTextRight" *ngIf="area === 'STATE';">
            TOP RE-ORDERED {{style}} <br> GOWNS IN MY STATE
          </div>
          <div class="topOrderTextRight" *ngIf="area === 'NATIONAL';">
            TOP RE-ORDERED {{style}} <br> GOWNS IN MY COUNTRY
          </div>
          <div class="area">
            <div [ngClass]="{'highlight': area === 'REGIONAL', 'no-highlight': area != 'REGIONAL' }"
              (click)="onClick('REGIONAL')">REGIONAL</div>
            <div [ngClass]="{'highlight': area === 'STATE', 'no-highlight': area != 'STATE' }"
              (click)="onClick('STATE')">STATE</div>
            <div [ngClass]="{'highlight': area === 'NATIONAL', 'no-highlight': area != 'NATIONAL' }"
              (click)="onClick('NATIONAL')">NATIONAL</div>
          </div>
          <div class="productsContainerPicture">
            <div *ngIf="area === 'REGIONAL'">
              <div class="products"
                *ngFor="let product of records.productLines[getStyleNumber(style)].sections.Region.products">
                <div class="productInfoPicture">
                  <a href="{{product.orderUrl}}" target="_blank">
                    <div class="imageContainer"><img [src]="product.imageUrl"></div>
                  </a>
                  <div class="infoContainer">
                    <div class="info">
                      <b>{{product.name}}</b> <br>
                      {{product.topColor}}
                    </div>
                    <a class="order" href="{{product.orderUrl}}" target="_blank">Order Now</a>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="area === 'NATIONAL'">
              <div class="products"
                *ngFor="let product of records.productLines[getStyleNumber(style)].sections.Nation.products">
                <div class="productInfoPicture">
                  <a href="{{product.orderUrl}}" target="_blank">
                    <div class="imageContainer"><img [src]="product.imageUrl"></div>
                  </a>
                  <div class="infoContainer">
                    <div class="info">
                      <b>{{product.name}}</b> <br>
                      {{product.topColor}}
                    </div>
                    <a class="order" href="{{product.orderUrl}}" target="_blank">Order Now</a>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="area === 'STATE'">
              <div class="products"
                *ngFor="let product of records.productLines[getStyleNumber(style)].sections.StateProvince.products">
                <div class="productInfoPicture">
                  <a href="{{product.orderUrl}}" target="_blank">
                    <div class="imageContainer"><img [src]="product.imageUrl"></div>
                  </a>
                  <div class="infoContainer">
                    <div class="info">
                      <b>{{product.name}}</b> <br>
                      {{product.topColor}}
                    </div>
                    <a class="order" href="{{product.orderUrl}}" target="_blank">Order Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #no_top_sellers>
    <div *ngIf="isLoaded">
      <div class="notopsellers">No current re-ordered gowns</div>
    </div>
  </ng-template>
  <ng-template #no_products>
    <div class="notopsellers">No gowns meet replacement criteria.</div>
  </ng-template>
</div>